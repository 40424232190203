import { Menu, X, ChevronRight, UserRound, ArrowLeft } from 'lucide-react';
import { LogOut } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import LanguageSwitcher from '@/components/language-switcher';
import { useAuth } from '@/features/auth/context/auth';
import { useGetCustomerProfile } from '@/features/customer/api/use-get-customer-profile';
import Avatar from '@/features/files/components/avatar';
import { useGetSupplierProfile } from '@/features/supplier/api/use-get-supplier-profile';
import { useIsLoaded } from '@/hooks/use-is-loaded';
import useIsMobile from '@/hooks/use-is-mobile';
import { PublicMenuRoute, publicMenuRoutes } from '@/routes/public.routes';
const PublicHeader = () => {
  const isLoaded = useIsLoaded();
  const {
    status,
    profileType,
    signOut,
    checkLoginStatus
  } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const {
    t,
    ready
  } = useTranslation();
  const profile = useGetCustomerProfile();
  const profileSupplier = useGetSupplierProfile();
  const avatar_sizes = profile?.data?.data.avatar_sizes;
  const userAvatar = avatar_sizes?.length ? avatar_sizes.find(avatar => avatar.size === 'thumbnail')?.url || avatar_sizes[0]?.url : null;
  const supplierAvatar = profileSupplier?.data?.data.avatar_sizes?.length ? profileSupplier?.data?.data.avatar_sizes.find(avatar => avatar.size === 'thumbnail')?.url || profileSupplier?.data?.data.avatar_sizes[0]?.url : null;
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const intervalId = setInterval(checkLoginStatus, 60000);
    return () => clearInterval(intervalId);
  }, [checkLoginStatus]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /**
   * Note, there is difference in the colors of language switcher and
   * image and the links up top. This is also in figma which is strange.
   * @todo: add the functionality of the language switcher and icons later */
  return <header className="sticky top-0 z-50 h-20 min-h-[80px] flex items-center justify-around gap-2 bg-[#fcfcfc] border-b" data-sentry-component="PublicHeader" data-sentry-source-file="public-header.tsx">
      <div className={`${!isLoaded ? 'hidden' : ''} w-full flex justify-center`}>
        {isMobile ? <>
            <div className="relative w-full max-w-screen-2xl flex justify-between items-center px-6">
              <button onClick={() => setIsOpen(!isOpen)} className="text-text" aria-label="Menu">
                {isOpen ? <X size={28} /> : <Menu size={28} />}
              </button>
              <div ref={menuRef} className="relative w-full flex items-center justify-around">
                <Link href="/" aria-label="Homepage">
                  <Image src="/images/header/icon.svg" alt="ofertirai.me" width={231} height={46} className="w-full max-w-[231px]" priority />
                </Link>
              </div>
              <div className="">
                {profileType === 'CUSTOMER' && <Link href="/customer/dashboard" aria-label="Customer Dashboard">
                    {userAvatar ? <div className="w-[40px] h-[40px] border-emerald-600 border-2 rounded-full">
                        <Avatar src={userAvatar} size={48} autoSize={true} />
                      </div> : <UserRound size={28} stroke="#2e7d32" />}
                  </Link>}
                {profileType === 'SUPPLIER' && <Link href="/supplier/dashboard" aria-label="Supplier Dashboard">
                    {userAvatar ? <div className="w-[40px] h-[40px] border-amber-400 border-2 rounded-full">
                        <Avatar src={userAvatar} size={48} autoSize={true} />
                      </div> : <UserRound size={28} stroke="#f2cb0c" />}
                  </Link>}
                {profileType !== 'SUPPLIER' && profileType !== 'CUSTOMER' && !status && <Link href="/login" aria-label="Login" className="text-text">
                    <UserRound size={28} />
                  </Link>}
                {profileType !== 'SUPPLIER' && profileType !== 'CUSTOMER' && status && <Link href="/login" aria-label="Login" className="text-text">
                    <UserRound size={28} />
                  </Link>}
              </div>
            </div>
            <div className={`fixed top-0 left-0 w-[80vw] sm:w-[40vw] h-full flex flex-col justify-between pb-4 bg-white border-r overflow-x-hidden transition-transform duration-300 ease-in-out z-10 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
              <nav className="flex flex-col items-start p-4">
                <span className="text-text mb-10 cursor-pointer" onClick={() => {
              setIsOpen(false);
            }}>
                  <ArrowLeft size={24} />
                </span>
                {publicMenuRoutes.map((route: PublicMenuRoute) => <Link key={route.id} href={route.url} className={`text-lg uppercase mb-2 no-underline py-2 text-border border-border border-b-2 flex items-center justify-between w-full ${route.disabled ? 'pointer-events-none opacity-50' : ''}`} onClick={() => setIsOpen(false)} suppressHydrationWarning>
                    {t(route?.name || '')}
                    <ChevronRight size={24} className="text-text" />
                  </Link>)}
              </nav>
              {status && <div className="px-4">
                  <Link href="#" className="text-lg uppercase mb-2 no-underline py-2 text-text border-border border-b-2 flex items-center justify-between w-full" onClick={signOut} suppressHydrationWarning>
                    <LogOut size={24} className="text-text" />
                    {t('EXIT')}
                    <ChevronRight size={24} className="text-text" />
                  </Link>
                </div>}
              <div className="p-4">
                <LanguageSwitcher />
              </div>
            </div>
          </> : <div className="w-full max-w-screen-2xl flex justify-between items-center px-6 2xl:px-6">
            <Link href="/" aria-label="Homepage">
              <Image src="/images/header/icon.svg" alt="ofertirai.me" width={231} height={46} className={`md:w-[165px] xl:w-[200.42px] transition-all ${!isLoaded ? 'hidden' : ''}`} priority />
            </Link>
            <nav className="hidden md:flex items-center gap-8">
              {publicMenuRoutes.map((route: PublicMenuRoute) => <Link key={route.id} href={route.url} className={`text-md underline-offset-4 hover:underline ${route?.disabled ? 'pointer-events-none opacity-50' : ''}`} suppressHydrationWarning>
                  {ready ? t(route?.name || '') : ''}
                </Link>)}
              <div className="px-3">
                <LanguageSwitcher />
              </div>
              {profileType === 'CUSTOMER' && <Link href="/customer/dashboard" aria-label="Customer Dashboard">
                  {userAvatar ? <div className="w-[40px] h-[40px] border-emerald-600 border-2 rounded-full">
                      <Avatar src={userAvatar} size={48} autoSize={true} />
                    </div> : <Image src="/images/header/account.svg" alt={t('IMAGES.ACCOUNT')} width={24} height={24} className={`rounded-full bg-white border-emerald-600 border-[3px] w-[38px] p-[6px]`} />}
                </Link>}
              {profileType === 'SUPPLIER' && <Link href="/supplier/dashboard" aria-label="Supplier Dashboard">
                  {supplierAvatar ? <div className="w-[40px] h-[40px] border-amber-400 border-2 rounded-full">
                      <Avatar src={supplierAvatar} size={48} autoSize={true} />
                    </div> : <Image src="/images/header/account.svg" alt={t('IMAGES.ACCOUNT')} width={24} height={24} className={`rounded-full bg-white border-amber-400 border-[3px] w-[38px] p-[6px]`} />}
                </Link>}
              {profileType !== 'SUPPLIER' && profileType !== 'CUSTOMER' && <div className="flex items-center gap-x-4">
                  <Link href="/login" aria-label="Login">
                    {t('LOGIN.BUTTON')}
                  </Link>
                  <Link href="/register" suppressHydrationWarning className="min-w-[182px] text-center text-main no-underline border-primary border-[1px] bg-white rounded-full px-4 py-2 transition-all hover:bg-primary hover:!text-white">
                    {t('REGISTER.BUTTON')}
                  </Link>
                </div>}
            </nav>
          </div>}
      </div>
    </header>;
};
export default PublicHeader;