import { X } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstallPWA } from '../hooks/useInstallPWA';
export const InstallPWA = () => {
  const {
    supportsPWA,
    installApp,
    isIOS,
    isInstalled,
    isDeclined,
    declineInstall
  } = useInstallPWA();
  const [isOpen, setIsOpen] = useState(true);
  const {
    t
  } = useTranslation();
  if (!supportsPWA || !isOpen || isInstalled || isDeclined) {
    return null;
  }
  const handleClose = () => {
    setIsOpen(false);
    declineInstall();
  };
  return <div className="fixed bottom-4 left-4 right-4 md:left-auto md:right-4 md:w-96 bg-white rounded-lg shadow-lg p-4 border border-gray-200" data-sentry-component="InstallPWA" data-sentry-source-file="InstallPWA.tsx">
      <div className="flex items-start justify-between">
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-gray-900">
            {isIOS ? t('PWA_DIALOG.IOS_TITLE') : t('PWA_DIALOG.TITLE')}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {isIOS ? t('PWA_DIALOG.IOS_DESCRIPTION') : t('PWA_DIALOG.DESCRIPTION')}
          </p>
        </div>
        <button onClick={handleClose} className="ml-4 text-gray-400 hover:text-gray-500">
          <span className="sr-only">{t('PWA_DIALOG.CLOSE')}</span>
          <X data-sentry-element="X" data-sentry-source-file="InstallPWA.tsx" />
        </button>
      </div>
      <div className="mt-4">
        <button onClick={installApp} className="w-full bg-blue-600 text-white rounded-md px-4 py-2 text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          {isIOS ? t('PWA_DIALOG.IOS_INSTALL') : t('PWA_DIALOG.INSTALL')}
        </button>
      </div>
    </div>;
};