import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const PWA_INSTALLED_KEY = 'pwa_installed';
const PWA_DECLINED_KEY = 'pwa_declined';

export const useInstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  //TODO: fix any
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [promptInstall, setPromptInstall] = useState<any>(null);
  const [isIOS, setIsIOS] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const installed = localStorage.getItem(PWA_INSTALLED_KEY);
    const declined = localStorage.getItem(PWA_DECLINED_KEY);

    if (installed === 'true') {
      setIsInstalled(true);

      return;
    }

    if (declined === 'true') {
      setIsDeclined(true);

      return;
    }

    // iOS
    //TODO: fix any
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    setIsIOS(isIOSDevice);

    if (isIOSDevice) {
      setSupportsPWA(true);

      return;
    }

    //  Android
    //TODO: fix any
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handler = (e: any) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };

    window.addEventListener('beforeinstallprompt', handler);

    window.addEventListener('appinstalled', () => {
      setIsInstalled(true);
      localStorage.setItem(PWA_INSTALLED_KEY, 'true');
    });

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const installApp = async () => {
    if (isIOS) {
      //IOS instuctions
      alert(
        `${t('PWA_DIALOG.INSTALL_INSTRUCTIONS.STEP_1')}\n
        ${t('PWA_DIALOG.INSTALL_INSTRUCTIONS.STEP_2')}\n
        ${t('PWA_DIALOG.INSTALL_INSTRUCTIONS.STEP_3')}\n
        ${t('PWA_DIALOG.INSTALL_INSTRUCTIONS.STEP_4')}`,
      );

      return;
    }

    if (!promptInstall) {
      return;
    }

    const result = await promptInstall.prompt();
    setPromptInstall(null);

    return result;
  };

  const declineInstall = () => {
    setIsDeclined(true);
    localStorage.setItem(PWA_DECLINED_KEY, 'true');
  };

  return {
    supportsPWA,
    promptInstall,
    installApp,
    isIOS,
    isInstalled,
    isDeclined,
    declineInstall,
  };
};
